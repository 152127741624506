import 'react-multi-carousel/lib/styles.css';

import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';

import { urlJoin } from '@digital-spiders/misc-utils';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import type { ArticleCore } from '../../api/types';
import { ARTICLE_PREFIX_URL } from '../../constants';
import { BREAKPOINTS } from '../../styles/breakpoints';
import * as styles from './ArticleCarousel.module.scss';
import EdgeCutWithDynamicWidth from './EdgeCutWithDynamicWidth';
import Image from './Image';
import SmartLink from './SmartLink';
import TextLink from './TextLink';

interface ArticleCarouselProps {
  articles: Array<ArticleCore>;
}

const responsive = {
  mobile: {
    breakpoint: { min: 0, max: 720 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { min: 720, max: 960 },
    items: 1,
    partialVisibilityGutter: 60,
  },
  tabletHorizontal: {
    breakpoint: { min: 960, max: 1100 },
    items: 1,
    partialVisibilityGutter: 90,
  },
  smallLaptop: {
    breakpoint: { min: 1100, max: 1350 },
    items: 1,
    partialVisibilityGutter: 200,
  },
  laptop: {
    breakpoint: { min: 1350, max: 1750 },
    items: 1,
    partialVisibilityGutter: 350,
  },
  desktop: {
    breakpoint: { min: 1750, max: 3000 },
    items: 1,
    partialVisibilityGutter: 400,
  },
};

const ButtonGroup = ({
  next,
  previous,
  carouselState: { currentSlide, totalItems, slidesToShow },
}: {
  next: () => void;
  previous: () => void;
  carouselState: { currentSlide: number; totalItems: number; slidesToShow: number };
}) => {
  return (
    <div className={styles.buttonGroup}>
      <button
        disabled={currentSlide === 0}
        className={styles.button + ' ' + styles.buttonLeft}
        onClick={() => previous()}
      >
        <BsArrowLeft className={styles.buttonIcon} />
        <div className={styles.visuallyHidden}>Previous</div>
      </button>

      <button
        disabled={currentSlide === totalItems - slidesToShow}
        className={styles.button + ' ' + styles.buttonRight}
        onClick={() => next()}
      >
        <BsArrowRight className={styles.buttonIcon} />
        <div className={styles.visuallyHidden}>Next</div>
      </button>
    </div>
  );
};

const ArticleCarousel = ({ articles }: ArticleCarouselProps): React.ReactElement => {
  const [currentIndex, setCurrentIndex] = useState(0);

  function renderArticleCard(article: ArticleCore) {
    return (
      <EdgeCutWithDynamicWidth
        sideHorizontal="right"
        sideVertical="top"
        proportion={1 / 4}
        className={styles.edgeCut}
        cutClassName={styles.hoverCut}
        hoverClassName={styles.card}
      >
        {article.image && (
          <div className={styles.imageContainer}>
            <Image
              image={article.image}
              className={styles.backgroundImage}
              dimensions={[
                [450, 450],
                [BREAKPOINTS.tabletVertical, 580, 320],
                [BREAKPOINTS.tabletHorizontal, 800, 320],
                [BREAKPOINTS.smallLaptop, 920, 450],
                [BREAKPOINTS.laptop, 1050, 450],
              ]}
              cover
            />
          </div>
        )}
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          {article.category && <span className={styles.categoryTag}>{article.category.title}</span>}
          <div className={styles.textWrapper}>
            <h3 className={styles.title}>{article.title}</h3>
            <TextLink styleOnly withArrow className={styles.link}>
              Read article
            </TextLink>
          </div>
        </div>
      </EdgeCutWithDynamicWidth>
    );
  }

  return (
    <Carousel
      arrows={false}
      draggable={false}
      responsive={responsive}
      ssr={true}
      containerClass={styles.carousel}
      // @ts-ignore
      customButtonGroup={<ButtonGroup />}
      renderButtonGroupOutside
      partialVisible
      showDots={false}
      shouldResetAutoplay={false}
      pauseOnHover
      focusOnSelect={true}
      beforeChange={nextSlide => {
        setCurrentIndex(nextSlide);
      }}
    >
      {articles.map((article, i) => (
        <div key={article._id} className={styles.card}>
          <SmartLink
            to={i === currentIndex ? urlJoin(ARTICLE_PREFIX_URL, article.slug.current) : '#'}
            className={styles.cardLink}
            key={article._id}
          >
            {renderArticleCard(article)}
          </SmartLink>
        </div>
      ))}
    </Carousel>
  );
};

export default ArticleCarousel;
